<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7637 2.524C13.5066 2.50503 13.249 2.49552 12.9912 2.4955V0.995605C13.286 0.995749 13.5807 1.00676 13.8747 1.0286L13.7637 2.524ZM16.7695 3.19896C16.2887 3.01343 15.7949 2.86353 15.2921 2.75049L15.6205 1.28659C16.195 1.41558 16.7605 1.58657 17.3094 1.79955L16.7695 3.19896ZM18.8243 4.26389C18.6099 4.12087 18.3902 3.98579 18.1659 3.85891L18.9053 2.554C19.4181 2.84458 19.9089 3.17244 20.3737 3.53493L19.4513 4.71835C19.2479 4.55971 19.0388 4.40862 18.8243 4.26539V4.26389ZM21.5751 6.9487C21.2784 6.5272 20.9511 6.12814 20.5957 5.75479L21.6816 4.71985C22.0866 5.14733 22.4616 5.60479 22.8021 6.08476L21.5751 6.9487ZM22.6911 8.97657C22.5926 8.73877 22.4855 8.50463 22.3701 8.27462L23.7095 7.59966C23.9744 8.12625 24.2 8.6717 24.3844 9.23155L22.9595 9.70102C22.8789 9.45633 22.7894 9.21469 22.6911 8.97657ZM23.486 12.7368C23.4739 12.2214 23.4237 11.7075 23.336 11.1994L24.8134 10.9444C24.9139 11.5234 24.9724 12.1114 24.9874 12.6993L23.4875 12.7368H23.486ZM23.2895 15.0437C23.339 14.7887 23.3795 14.5352 23.411 14.2787L24.9004 14.4632C24.8284 15.0484 24.7131 15.6274 24.5554 16.1956L23.1095 15.7951C23.1785 15.5476 23.2385 15.2971 23.2895 15.0437ZM21.8616 18.6119C22.1376 18.1769 22.3806 17.721 22.5906 17.25L23.9615 17.8575C23.7215 18.3974 23.444 18.9164 23.129 19.4144L21.8616 18.6119ZM20.4157 20.4193C20.5987 20.2363 20.7742 20.0473 20.9407 19.8523L22.0776 20.8318C21.8851 21.055 21.6844 21.2711 21.4761 21.4797L20.4157 20.4193Z"
      fill="#5B5D62"
    />
    <path
      d="M12.9914 2.4955C11.2649 2.49563 9.56497 2.92155 8.04233 3.73553C6.51968 4.54951 5.22127 5.72644 4.2621 7.16207C3.30293 8.59769 2.71261 10.2477 2.54342 11.966C2.37423 13.6842 2.6314 15.4177 3.29214 17.0128C3.95289 18.6079 4.99682 20.0155 6.33146 21.1108C7.66611 22.2062 9.25028 22.9554 10.9437 23.2923C12.637 23.6292 14.3874 23.5432 16.0396 23.0421C17.6918 22.541 19.195 21.6401 20.4159 20.4193L21.4764 21.4797C20.0811 22.8758 18.3629 23.9062 16.4743 24.4795C14.5856 25.0529 12.5846 25.1515 10.6487 24.7667C8.71275 24.3819 6.90162 23.5256 5.37576 22.2735C3.8499 21.0215 2.65642 19.4124 1.90107 17.5888C1.14572 15.7653 0.851823 13.7836 1.04541 11.8193C1.239 9.855 1.9141 7.96879 3.0109 6.32777C4.1077 4.68676 5.59233 3.34161 7.33325 2.41151C9.07417 1.48142 11.0176 0.995082 12.9914 0.995606V2.4955Z"
      fill="#5B5D62"
    />
    <path
      d="M12.2416 5.49512C12.4405 5.49512 12.6313 5.57413 12.7719 5.71477C12.9126 5.85542 12.9916 6.04617 12.9916 6.24507V14.0595L17.8633 16.8434C18.0309 16.9445 18.1524 17.1072 18.2017 17.2968C18.251 17.4863 18.2242 17.6876 18.127 17.8576C18.0299 18.0276 17.8701 18.1529 17.6818 18.2066C17.4935 18.2604 17.2916 18.2384 17.1193 18.1453L11.8697 15.1455C11.7549 15.0799 11.6595 14.9852 11.5931 14.8708C11.5267 14.7565 11.4917 14.6267 11.4917 14.4945V6.24507C11.4917 6.04617 11.5707 5.85542 11.7114 5.71477C11.852 5.57413 12.0427 5.49512 12.2416 5.49512Z"
      fill="#5B5D62"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheAwaitingConfirmationIcon',
};
</script>
