<template>
  <div class="vdatetime-calendar">
    <div class="vdatetime-calendar__navigation">
      <div class="vdatetime-calendar__navigation--button">
        <div v-show="enablePreviousMonth" class="vdatetime-calendar__navigation--previous" @click="previousMonth">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.3 102.8">
            <path fill="none" stroke="#444" stroke-width="14" stroke-miterlimit="10" d="M56.3 97.8L9.9 51.4 56.3 5" />
          </svg>
        </div>
      </div>

      <div class="vdatetime-calendar__current--month">{{ monthName }} {{ newYear }}</div>

      <div class="vdatetime-calendar__navigation--button">
        <div v-show="enableNextMonth" class="vdatetime-calendar__navigation--next" @click="nextMonth">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.3 102.8">
            <path fill="none" stroke="#444" stroke-width="14" stroke-miterlimit="10" d="M56.3 97.8L9.9 51.4 56.3 5" />
          </svg>
        </div>
      </div>
    </div>

    <div class="vdatetime-calendar__month">
      <div v-for="weekday in weekdays" :key="weekday" class="vdatetime-calendar__month-weekday">{{ weekday }}</div>
      <div
        v-for="(day, i) in days"
        :key="`day_${i}`"
        class="vdatetime-calendar__month-day"
        :class="{
          'vdatetime-calendar__month-day--in-range': !day.emptyPlaceholder && !day.disabled && day.inRange,
          'vdatetime-calendar__month-day--empty-placeholder': day.emptyPlaceholder,
          'vdatetime-calendar__month-day--disabled': day.disabled && !day.selected,
          'vdatetime-calendar__month-day--selected': day.selected,
        }"
        @click="selectDay(day)"
      >
        <span>{{ day.number }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { monthDayIsInRange, monthDays, months, weekdays } from './util';

export default {
  name: 'DatetimeCalendar',
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    day: {
      type: Number,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    minDate: {
      type: DateTime,
      default: null,
    },
    maxDate: {
      type: DateTime,
      default: null,
    },
    weekStart: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      newDate: DateTime.fromObject({ year: this.year, month: this.month }, { zone: 'UTC' }),
      weekdays: weekdays(this.weekStart),
      months: months(),
    };
  },
  computed: {
    newYear() {
      return this.newDate.year;
    },
    newMonth() {
      return this.newDate.month;
    },
    monthName() {
      return this.months[this.newMonth - 1];
    },
    days() {
      return monthDays(this.newYear, this.newMonth, this.weekStart).map((day) => {
        const disabled =
          this.disabledDates.findIndex((dd) => {
            return dd.year === this.newYear && dd.month === this.newMonth && dd.day === day;
          }) !== -1;

        return {
          disabled,
          emptyPlaceholder: !day,
          inRange: monthDayIsInRange(this.minDate, this.maxDate, this.newYear, this.newMonth, day),
          number: day,
          selected: day && this.year === this.newYear && this.month === this.newMonth && this.day === day,
        };
      });
    },
    enablePreviousMonth() {
      const previousDate = this.newDate.minus({ months: 1 });
      return previousDate.endOf('month') >= this.minDate.startOf('day');
    },
    enableNextMonth() {
      if (!this.maxDate) {
        return true;
      }
      const nextDate = this.newDate.plus({ months: 1 });
      return nextDate.startOf('month') <= this.maxDate.startOf('day');
    },
  },
  methods: {
    selectDay(day) {
      if (day.emptyPlaceholder || day.disabled || (this.minDate && this.maxDate && !day.inRange)) {
        return;
      }
      this.$emit('change', this.newYear, this.newMonth, day.number);
      this.$emit('confirm');
    },
    previousMonth() {
      this.newDate = this.newDate.minus({ months: 1 });
    },
    nextMonth() {
      this.newDate = this.newDate.plus({ months: 1 });
    },
  },
};
</script>
