<template>
  <div class="vdatetime-year-picker">
    <div ref="yearList" class="vdatetime-year-picker__list vdatetime-year-picker__list">
      <div
        v-for="year in years"
        :key="`year-${year.number}`"
        class="vdatetime-year-picker__item"
        :class="{
          'vdatetime-year-picker__item--selected': year.selected,
          'vdatetime-year-picker__item--disabled': year.disabled,
        }"
        @click="select(year)"
      >
        {{ year.number }}
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { yearIsDisabled, years } from './util';

export default {
  props: {
    maxDate: {
      type: DateTime,
      default: null,
    },
    minDate: {
      type: DateTime,
      default: null,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  computed: {
    years() {
      return years(this.year).map((year) => ({
        disabled: !year || yearIsDisabled(this.minDate, this.maxDate, year),
        number: year,
        selected: year === this.year,
      }));
    },
  },

  mounted() {
    this.scrollToCurrent();
  },
  updated() {
    this.scrollToCurrent();
  },

  methods: {
    select(year) {
      if (year.disabled) {
        return;
      }

      this.$emit('change', parseInt(year.number));
    },
    scrollToCurrent() {
      const selectedYear = this.$refs.yearList.querySelector('.vdatetime-year-picker__item--selected');

      this.$refs.yearList.scrollTop = selectedYear ? selectedYear.offsetTop - 250 : 0;
    },
  },
};
</script>
