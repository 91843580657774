

















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { EntityTypes } from '@/helpers/entityType';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { SF_CASE_INSTRUCTIONS_MAXLENGTH } from '@/helpers/formFields';

import ticketRequestTourTypesQuery from '@/graphql/ticketRequest/TicketRequestTourTypes.query.gql';
import bookTicketsMutation from '@/graphql/booking/BookTickets.mutation.gql';

import DatetimeWidget from '@/components/partials/date/DatetimeWidget.vue';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import SelectOption = App.SelectOption;

@Component({
  components: {
    TheArrowHorizIcon,
    TheCrossIcon,
    TheArrowDownIcon,
    TheCheckedMarkIcon,
    DatetimeWidget,
  },
})
export default class TicketRequest extends Vue {
  @Prop({ required: true, type: String }) actionLabel: string;
  @Prop({ default: true, type: Boolean }) dateTime: boolean;
  @Prop() event!: any;
  @Prop({ default: [], type: Array }) exhibitions: any[];
  @Prop() object!: string;
  @Prop() objectId!: number;
  @Prop({ default: false, type: Boolean }) selectFromExhibitions: boolean;
  @Prop({ default: false, type: Boolean }) tour: boolean;

  public selectedStep = 0;

  public selectedExhibitionId: number | string | null = null;
  public selectedTours: number[] = [];
  public tours: SelectOption[] = [];

  public bookingDateDaysDiffNow: number = 0;
  public rawBookingDate: string = '';

  public guestsCount = 1;
  public notes = '';
  public readonly notesFieldMaxlength: number = SF_CASE_INSTRUCTIONS_MAXLENGTH;

  get guestsValue() {
    return this.selectedExhibition ? this.selectedExhibition.max_guests : this.event.max_guests;
  }

  get museumRole() {
    return this.$store.state.museumRole;
  }

  get requestedObjectId() {
    return this.selectedExhibition ? this.selectedExhibition.id : this.objectId;
  }

  get requestedObjectType() {
    return this.selectFromExhibitions ? EntityTypes.exhibition : this.event.entityTypename || this.event.__typename;
  }

  get selectedExhibition() {
    return this.selectedExhibitionId ? this.exhibitions.find((ex: any) => ex.id === this.selectedExhibitionId) : null;
  }

  get isArtSpaceAsNonPartnerMuseum() {
    return (
      this.event.__typename === EntityTypes.historical_site ||
      (this.object === 'exhibition' &&
        !(this.event.artSpace ? this.event.artSpace.partner_museum : this.event.partner_museum))
    );
  }

  get bookingDate() {
    if (!this.rawBookingDate) {
      return null;
    }

    const dt = DateTime.fromISO(this.rawBookingDate);

    if (dt.hour === 0 && dt.minute === 0) {
      return null;
    }

    return dt;
  }

  get formattedBookingDate() {
    return this.bookingDate ? this.bookingDate.toSQL({ includeOffset: false }) : '';
  }

  get minDate() {
    const minDt = DateTime.now().plus({ days: 1 }).set({ hour: 8, minute: 0, second: 0 });

    if (this.earliestStartDate && this.earliestStartDate > minDt) {
      return this.earliestStartDate.toISO();
    }
    return minDt.toISO();
  }

  get maxDate() {
    const nowDt = DateTime.now();

    if (this.latestEndDate && this.latestEndDate > nowDt) {
      return this.latestEndDate.toISO();
    }
    return nowDt.endOf('year').plus({ years: 5 }).set({ hour: 22, minute: 0, second: 0 }).toISO();
  }

  get earliestStartDate() {
    return this.getBookEntityStartEndDate('earliest_start_date');
  }

  get latestEndDate() {
    return this.getBookEntityStartEndDate('latest_end_date');
  }

  get stepTitle() {
    return this.steps[this.selectedStep].title;
  }

  get stepsCount() {
    return this.steps.length;
  }

  get isFirstStep() {
    return this.selectedStep === 0;
  }

  get isLastStep() {
    return this.selectedStep + 1 === this.stepsCount;
  }

  get currentStep() {
    return this.steps[this.selectedStep];
  }

  get steps() {
    const steps = [];

    if (this.selectFromExhibitions) {
      steps.push({
        type: 'selectFromExhibitions',
        title: 'Select from exhibitions',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.selectedExhibitionId) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select at least one exhibition.');
        },
      });
    }

    if (this.tour) {
      steps.push({
        type: 'tour',
        title: 'Select tour type',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.selectedTours.length) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select at least one tour.');
        },
      });
    }

    if (this.museumRole && this.object === 'academy') {
      steps.push({
        type: 'book_your_academy_session',
        title: 'Book your session',
      });
    }

    if (this.dateTime) {
      steps.push({
        type: 'dateTime',
        title: 'Select date & time',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.bookingDate) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select the date & time.');
        },
      });
    }

    if (this.guestsValue) {
      steps.push({
        type: 'guests',
        title: 'Add guests',
      });
    }

    return steps;
  }

  get showArrangingTourNotice() {
    return this.tour && this.bookingDateDaysDiffNow <= 2;
  }

  @Watch('bookingDate', { immediate: true })
  watchMinServiceDate(newDate: DateTime | null) {
    if (newDate) {
      this.bookingDateDaysDiffNow = Math.floor(newDate.diff(DateTime.now().startOf('day'), 'days').days);
    }
  }

  @Watch('selectFromExhibitions', { immediate: true })
  watchSelectFromExhibitions(newValue: boolean) {
    if (newValue && this.exhibitions.length === 1) {
      this.selectedExhibitionId = this.exhibitions[0].id;
    }
  }

  created() {
    if (this.tour) {
      this.fetchBookTourTypes();
    }
  }

  async fetchBookTourTypes() {
    const result = await this.$apollo.query({
      query: ticketRequestTourTypesQuery,
      fetchPolicy: 'no-cache',
    });
    this.tours = (result?.data?.ticketRequestTourTypes || []).map((type: string, idx: number) => ({
      title: type,
      value: idx,
    }));
  }

  getBookEntityStartEndDate(field: 'earliest_start_date' | 'latest_end_date') {
    const dateEntity = this.selectedExhibition ? this.selectedExhibition : this.event;
    let dt = null;

    if (dateEntity[field]) {
      dt = DateTime.fromJSDate(new Date(dateEntity[field]));

      if (!dt.isValid) {
        dt = null;
      }
    }

    return dt;
  }

  async toNextStep() {
    const validateStep = this.steps[this.selectedStep].validate;

    if (validateStep) {
      try {
        await validateStep();
      } catch {
        const onError = this.steps[this.selectedStep].onError;
        if (onError) {
          onError();
        }
        return;
      }
    }

    if (this.isLastStep) {
      this.submit();
    } else {
      this.selectedStep++;
    }
  }

  toPrevStep() {
    if (!this.isFirstStep) {
      this.selectedStep--;
    }
  }

  submit() {
    this.$emit('show-loader');

    this.$apollo
      .mutate({
        mutation: bookTicketsMutation,
        variables: {
          input: {
            object: this.requestedObjectType,
            object_id: this.requestedObjectId,
            use_art_space: this.selectFromExhibitions,
            quantity: this.guestsCount,
            start_date: this.formattedBookingDate,
            user_notes: this.notes,
          },
        },
      })
      .then((res: any) => {
        if (res) {
          this.$toast.success('Thank you! The Team will come back to you with confirmation.');
          this.$emit('hide-loader');
          this.$emit('close');
          this.$emit('requested');
        }
      })
      .catch((err: any) => {
        const error = err?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;

        this.$emit('hide-loader');
        this.$toast.error(error);
      });
  }

  close() {
    this.$emit('close');
  }

  isTourSelected(value: number) {
    return this.selectedTours.includes(value);
  }
}
