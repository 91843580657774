<template>
  <label>
    <span class="label-text">{{ label }}</span>
    <input v-model.number="vModel" :min="min" type="number" />
  </label>
</template>

<script>
export default {
  name: 'BookingFormGuestsElement',
  props: {
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    value: {
      type: [Number, String],
    },
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
label,
input {
  width: 100%;
}

label,
.label-text {
  display: block;
}

label {
  margin-bottom: 28px;
}

.label-text {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

input {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  color: #363636;
  border: 1px solid #acacac;

  &::placeholder {
    color: #999;
  }

  @media (min-width: 768px) {
    font-size: 15px;
  }
}
</style>
