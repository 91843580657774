<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" fill="white" stroke="#5B5D62" stroke-width="1.5"/>
    <path d="M16 8L8 16M16 16L8 8L16 16Z" stroke="#5B5D62" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TheNotConfirmedIcon',
};
</script>
