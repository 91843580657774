<template>
  <div class="vdatetime-popup" :class="{ 'time-popup': type === 'time' }">
    <div class="vdatetime-popup__header">
      <div v-if="type !== 'time'" class="vdatetime-popup__year" @click="showYear">{{ year }}</div>
      <div v-if="type !== 'time'" class="vdatetime-popup__date">{{ dateFormatted }}</div>
    </div>

    <div class="vdatetime-popup__body">
      <DatetimeYearPicker
        v-if="step === 'year'"
        :max-date="maxDatetimeUTC"
        :min-date="minDatetimeUTC"
        :year="year"
        @change="onChangeYear"
      />
      <DatetimeCalendar
        v-if="step === 'date'"
        :day="day"
        :disabled-dates="disabledDates"
        :max-date="maxDatetimeUTC"
        :min-date="minDatetimeUTC"
        :month="month"
        :year="year"
        :week-start="weekStart"
        @change="onChangeDate"
        @confirm="confirm"
      />
      <DatetimeTimePicker
        v-if="step === 'time'"
        :hour="hour"
        :hour-step="hourStep"
        :max-time="maxTime"
        :min-time="minTime"
        :minute="minute"
        :minute-step="minuteStep"
        :use12-hour="use12Hour"
        @change="onChangeTime"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { createFlowManagerFromType } from './util';

import DatetimeCalendar from './DatetimeCalendar';
import DatetimeTimePicker from './DatetimeTimePicker';
import DatetimeYearPicker from './DatetimeYearPicker';

const KEY_TAB = 9;
const KEY_ENTER = 13;
const KEY_ESC = 27;

export default {
  name: 'DatetimePopup',
  components: {
    DatetimeCalendar,
    DatetimeTimePicker,
    DatetimeYearPicker,
  },
  props: {
    auto: {
      type: Boolean,
      default: false,
    },
    datetime: {
      type: DateTime,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    hourStep: {
      type: Number,
      default: 1,
    },
    maxDatetime: {
      type: DateTime,
      default: null,
    },
    minDatetime: {
      type: DateTime,
      default: null,
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    phrases: {
      type: Object,
      default: () => ({
        cancel: 'Cancel',
        ok: 'Ok',
      }),
    },
    type: {
      type: String,
      default: 'date',
    },
    use12Hour: {
      type: Boolean,
      default: false,
    },
    weekStart: {
      type: Number,
      default: 1,
    },
  },

  data() {
    const flow = createFlowManagerFromType(this.type);

    return {
      flow,
      newDatetime: this.datetime || this.minDatetime,
      step: flow.first(),
      timePartsTouched: [],
    };
  },

  computed: {
    year() {
      return this.newDatetime.year;
    },
    month() {
      return this.newDatetime.month;
    },
    day() {
      return this.datetime ? this.datetime.day : null;
    },
    hour() {
      return this.newDatetime.hour;
    },
    minute() {
      return this.newDatetime.minute;
    },
    dateFormatted() {
      return this.newDatetime.toLocaleString({
        month: 'long',
        day: 'numeric',
      });
    },
    minDatetimeUTC() {
      return this.minDatetime ? this.minDatetime.toUTC() : null;
    },
    maxDatetimeUTC() {
      return this.maxDatetime ? this.maxDatetime.toUTC() : null;
    },
    minTime() {
      return this.minDatetime &&
        this.minDatetime.year === this.year &&
        this.minDatetime.month === this.month &&
        this.minDatetime.day === this.day
        ? this.minDatetime.toFormat('HH:mm')
        : null;
    },
    maxTime() {
      return this.maxDatetime &&
        this.maxDatetime.year === this.year &&
        this.maxDatetime.month === this.month &&
        this.maxDatetime.day === this.day
        ? this.maxDatetime.toFormat('HH:mm')
        : null;
    },
  },

  created() {
    if (!this.datetime) {
      this.newDatetime = this.newDatetime.startOf('month');
    }
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },

  methods: {
    nextStep() {
      this.step = this.flow.next(this.step);
      this.timePartsTouched = [];

      if (this.step === 'end') {
        this.$emit('confirm', this.newDatetime);
      }
    },
    showYear() {
      this.step = 'year';
      this.flow.diversion('date');
    },
    confirm() {
      this.nextStep();
    },
    cancel() {
      this.$emit('cancel');
    },
    onChangeYear(year) {
      this.newDatetime = this.newDatetime.set({ year });

      if (this.auto) {
        this.nextStep();
      }
    },
    onChangeDate(year, month, day) {
      this.newDatetime = this.newDatetime.set({ year, month, day });

      if (this.auto) {
        this.nextStep();
      }
      this.confirm();
    },
    onChangeTime({ hour, minute, suffixTouched }) {
      if (suffixTouched) {
        this.timePartsTouched['suffix'] = true;
      }

      if (Number.isInteger(hour)) {
        this.newDatetime = this.newDatetime.set({ hour });
        this.timePartsTouched['hour'] = true;
      }

      if (Number.isInteger(minute)) {
        this.newDatetime = this.newDatetime.set({ minute });
        this.timePartsTouched['minute'] = true;
      }

      const goNext =
        this.auto &&
        this.timePartsTouched['hour'] &&
        this.timePartsTouched['minute'] &&
        (this.timePartsTouched['suffix'] || !this.use12Hour);

      if (goNext) {
        this.nextStep();
      }
      this.confirm();
    },
    onKeyDown(event) {
      switch (event.keyCode) {
        case KEY_ESC:
        case KEY_TAB:
          this.cancel();
          break;

        case KEY_ENTER:
          this.nextStep();
          break;
      }
    },
  },
};
</script>
