<template>
  <div :class="{ 'vdatetime-time-picker': true, 'vdatetime-time-picker__with-suffix': use12Hour }">
    <div ref="hourList" class="vdatetime-time-picker__list vdatetime-time-picker__list--hours">
      <div
        v-for="hour in hours"
        :key="`hour-${hour.number}`"
        class="vdatetime-time-picker__item"
        :class="{
          'vdatetime-time-picker__item--selected': hour.selected,
          'vdatetime-time-picker__item--disabled': hour.disabled,
        }"
        @click="selectHour(hour)"
      >
        <span class="vdatetime-time-picker__value">
          <span>{{ formatHour(hour.number) }}</span>
          <span class="vdatetime-time-picker__value-delimiter">:</span>
          <span>{{ formatMinute(hour.number) }}</span>
        </span>
        <span v-show="hour.selected" class="vdatetime-time-picker__selected-icon">
          <CheckedMarkIcon />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { hours, pad, timeComponentIsDisabled } from './util';
import CheckedMarkIcon from '@/components/icons/CheckedMarkIcon.vue';

export default {
  components: { CheckedMarkIcon },
  props: {
    hour: {
      type: Number,
      required: true,
    },
    hourStep: {
      type: Number,
      default: 1,
    },
    maxTime: {
      type: String,
      default: null,
    },
    minTime: {
      type: String,
      default: null,
    },
    minute: {
      type: Number,
      required: true,
    },
    use12Hour: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hours() {
      return hours(this.hourStep)
        .filter((hour) => {
          if (!this.use12Hour) {
            return true;
          }
          if (this.hour < 12) {
            return hour < 12;
          }
          return hour >= 12;
        })
        .map((hour) => ({
          disabled: timeComponentIsDisabled(this.minHour, this.maxHour, hour),
          number: pad(hour),
          selected: (this.minute === 0 && hour === this.hour) || (this.minute === 30 && hour === this.hour + 0.5),
        }));
    },
    maxHour() {
      return this.maxTime ? parseInt(this.maxTime.split(':')[0]) : null;
    },
    minHour() {
      return this.minTime ? parseInt(this.minTime.split(':')[0]) : null;
    },
  },

  mounted() {
    const selectedHour = this.$refs.hourList.querySelector('.vdatetime-time-picker__item--selected');

    this.$refs.hourList.scrollTop = selectedHour ? selectedHour.offsetTop - selectedHour.offsetHeight * 2 : 0;
  },

  methods: {
    selectHour(hour) {
      if (hour.disabled) {
        return;
      }

      const minute = String(hour.number).indexOf('.5') === -1 ? 0 : 30;

      this.$emit('change', { hour: parseInt(hour.number), minute });
    },
    formatHour(hour) {
      const numHour = Number(hour);

      if ((numHour ^ 0) !== numHour) {
        return Math.floor(hour) < 10 ? '0' + Math.floor(hour) : Math.floor(hour);
      }
      return pad(numHour);
    },
    formatMinute(hour) {
      const numHour = Number(hour);

      if ((numHour ^ 0) !== numHour) {
        return '30';
      }
      return '00';
    },
  },
};
</script>
