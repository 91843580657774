<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <defs>
      <path
        id="op9ra"
        d="M521 1042c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
      />
      <path
        id="op9rc"
        d="M530.5 1042.875a.75.75 0 0 1 0-1.5h4.875v-4.875a.75.75 0 0 1 1.5 0v4.875h4.875a.75.75 0 0 1 0 1.5h-4.875v4.875a.75.75 0 0 1-1.5 0v-4.875z"
      />
      <clipPath id="op9rb"><use fill="#fff" xlink:href="#op9ra" /></clipPath>
    </defs>
    <g>
      <g transform="translate(-521 -1027)">
        <g>
          <g>
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#363636"
              stroke-miterlimit="50"
              stroke-width="2.5"
              clip-path='url("#op9rb")'
              xlink:href="#op9ra"
            />
          </g>
          <g>
            <use fill="#363636" xlink:href="#op9rc" />
            <use
              fill="#fff"
              fill-opacity="0"
              stroke="#fff"
              stroke-miterlimit="50"
              stroke-width=".25"
              xlink:href="#op9rc"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ThePlusCircleIcon',
};
</script>
