<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="29"
    viewBox="0 0 20 29"
  >
    <defs>
      <path
        id="1urwa"
        fill="#363636"
        d="M728.56 1112.6c-3.21-.98-6.4-.5-9.49-.03-2.63.4-5.12.77-7.63.32v-12.04c3.2 1.05 5.98.21 8.46-.53 3.04-.91 5.68-1.7 8.66.89zm1.02-12.11c-3.57-3.28-6.86-2.3-10.03-1.34-2.52.76-5.11 1.51-8.1.39v-.65a.61.61 0 0 0-1.23 0v26.89a.61.61 0 0 0 1.22 0v-11.68c.81.14 1.63.21 2.43.21 1.82 0 3.62-.27 5.39-.53 3.35-.5 6.5-.98 9.69.25.19.07.4.05.56-.07a.6.6 0 0 0 .27-.5v-12.52a.61.61 0 0 0-.2-.45z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-710 -1098)">
        <use xlink:href="#1urwa"></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheBookTourIcon',
};
</script>
