<template>
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6412 7.10711C19.177 6.66129 18.9052 6.06367 18.8801 5.43376C18.8551 4.80384 19.0787 4.18807 19.5062 3.70959C19.5415 3.66979 19.5599 3.61864 19.5576 3.56638C19.5554 3.51412 19.5326 3.46462 19.4939 3.4278L17.0313 1.061C16.9906 1.02194 16.9354 1 16.8778 1C16.8203 1 16.7651 1.02194 16.7243 1.061L12.7991 4.82924C12.6543 4.96824 12.5452 5.13772 12.4805 5.32424C12.416 5.5112 12.307 5.68116 12.1622 5.82064C12.0174 5.96013 11.8407 6.0653 11.6462 6.12782C11.4518 6.19001 11.2751 6.29473 11.13 6.43371L1.06354 16.0954C1.02286 16.1345 1 16.1874 1 16.2427C1 16.2979 1.02286 16.3509 1.06354 16.39L3.52617 18.7541C3.56453 18.7913 3.61609 18.8131 3.67053 18.8153C3.72496 18.8175 3.77825 18.7999 3.8197 18.7659C4.31801 18.3551 4.95958 18.1401 5.61597 18.164C6.27235 18.1879 6.89512 18.4489 7.35959 18.8948C7.82406 19.3407 8.09596 19.9385 8.12085 20.5687C8.14574 21.1988 7.92179 21.8147 7.49383 22.2931C7.45847 22.3329 7.44009 22.384 7.44236 22.4363C7.44464 22.4886 7.46741 22.5381 7.5061 22.5749L9.96873 24.939C10.0094 24.9781 10.0646 25 10.1222 25C10.1797 25 10.2349 24.9781 10.2757 24.939L20.3427 15.2752C20.4874 15.1359 20.5965 14.9663 20.6613 14.7797C20.7258 14.5927 20.8348 14.4227 20.9796 14.2833C21.1244 14.1438 21.3011 14.0386 21.4956 13.9761C21.6899 13.914 21.8664 13.8092 22.0112 13.6702L25.9365 9.90195C25.9771 9.86287 26 9.80988 26 9.75462C26 9.69937 25.9771 9.64638 25.9365 9.6073L23.4738 7.24318C23.4355 7.20603 23.3839 7.18418 23.3295 7.18199C23.275 7.1798 23.2218 7.19745 23.1803 7.2314C22.6826 7.64249 22.0415 7.85807 21.3854 7.83503C20.7292 7.81198 20.1063 7.55202 19.6412 7.10711V7.10711Z"
      stroke="#363636"
      stroke-width="1"
      stroke-miterlimit="10"
    />
    <path
      d="M21 14L20.1428 13.1428M13.8572 6.85722L13 6L13.8572 6.85722ZM16.1428 9.14278L15.5711 8.57165L16.1428 9.14278ZM18.4283 11.4289L17.8572 10.8572L18.4283 11.4289Z"
      stroke="#363636"
      stroke-width="1"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheTicketIcon',
};
</script>
