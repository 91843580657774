import { DateTime, Info, Settings } from 'luxon';
import FlowManager from './FlowManager';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function datetimeFromISO(string) {
  const datetime = DateTime.fromISO(string).toUTC();

  return datetime.isValid ? datetime : null;
}

export function monthDays(year, month, weekStart) {
  const monthDate = DateTime.local(year, month, 1);
  let firstDay = monthDate.weekday - weekStart;

  if (firstDay < 0) {
    firstDay += 7;
  }
  let lastDay = (weekStart - monthDate.weekday - monthDate.daysInMonth) % 7;
  if (lastDay < 0) {
    lastDay += 7;
  }

  return new Array(monthDate.daysInMonth + firstDay + lastDay)
    .fill(null)
    .map((value, index) =>
      index + 1 <= firstDay || index >= firstDay + monthDate.daysInMonth ? null : index + 1 - firstDay
    );
}
export function monthDayIsInRange(minDate, maxDate, year, month, day) {
  const date = DateTime.fromObject({ year, month, day }, { zone: 'UTC' });

  minDate = minDate ? startOfDay(minDate) : null;
  maxDate = maxDate ? startOfDay(maxDate) : null;

  if (minDate && maxDate) {
    return minDate && date >= minDate && maxDate && date <= maxDate;
  }

  return false;
}

export function yearIsDisabled(minDate, maxDate, year) {
  const minYear = minDate ? minDate.year : null;
  const maxYear = maxDate ? maxDate.year : null;

  return (minYear && year < minYear) || (maxYear && year > maxYear);
}

export function timeComponentIsDisabled(min, max, component) {
  return (min && component < min) || (max && component > max);
}

export function weekdays(weekStart) {
  if (--weekStart < 0) {
    weekStart = 6;
  }

  const weekDays = Info.weekdays('short').map((weekday) => capitalize(weekday));

  return weekDays.concat(weekDays.splice(0, weekStart));
}

export function months() {
  return Info.months().map((month) => capitalize(month));
}

export function hours() {
  return [
    8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5,
    20, 20.5, 21, 21.5, 22,
  ];
}

export function minutes(step) {
  return new Array(Math.ceil(60 / step)).fill(null).map((item, index) => index * step);
}

export function years(current) {
  return new Array(201).fill(null).map((item, index) => current - 100 + index);
}

export function pad(number) {
  return number < 10 ? '0' + number : number;
}

export function startOfDay(datetime) {
  return datetime.startOf('day');
}

export function createFlowManagerFromType(type) {
  let flow;

  switch (type) {
    case 'datetime':
      flow = ['date', 'time'];
      break;
    case 'time':
      flow = ['time'];
      break;
    default:
      flow = ['date'];
  }

  return new FlowManager(flow, 'end');
}

export function weekStart() {
  const firstDay = window.weekstart ? window.weekstart.getWeekStartByLocale(Settings.defaultLocale) : 1;

  return firstDay === 0 ? 7 : firstDay;
}

export function isToday(pDate) {
  const now = new Date();

  return (
    now.getFullYear() === pDate.getFullYear() &&
    now.getMonth() === pDate.getMonth() &&
    now.getDate() === pDate.getDate()
  );
}
