

















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { EntityTypes } from '@/helpers/entityType';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { SF_CASE_INSTRUCTIONS_MAXLENGTH } from '@/helpers/formFields';

import eventTourTypesQuery from '@/graphql/booking/EventTourTypes.query.gql';
import eventTourDurationsQuery from '@/graphql/booking/EventTourDurations.query.gql';
import bookATourMutation from '@/graphql/booking/BookATour.mutation.gql';
import updateTourBookingMutation from '@/graphql/booking/UpdateTourBooking.mutation.gql';

import DatetimeWidget from '@/components/partials/date/DatetimeWidget.vue';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import BookingFormGuestsElement from '@/components/partials/BookingFormGuestsElement.vue';
import BookingFormTextarea from '@/components/partials/BookingFormTextarea.vue';
import SelectOption = App.SelectOption;
import TourBookingFormData = App.TourBookingFormData;
import TourBookingRequestInput = App.TourBookingRequestInput;

@Component({
  components: {
    BookingFormTextarea,
    BookingFormGuestsElement,
    TheArrowHorizIcon,
    TheCrossIcon,
    TheArrowDownIcon,
    TheCheckedMarkIcon,
    DatetimeWidget,
  },
})
export default class TourBookingForm extends Vue {
  @Prop() event!: any;
  @Prop({ default: [], type: Array }) exhibitions: any[];
  @Prop() object!: string;
  @Prop() objectId!: number;
  @Prop({ default: false, type: Boolean }) selectFromExhibitions: boolean;

  public formData: TourBookingFormData = {
    id: undefined,
    adultGuests: 1,
    childrenGuests: 0,
    duration: '',
    exhibitionId: '',
    notes: '',
    rawServiceDates: [],
    tourTypes: [],
  };

  public bookingDateDaysDiffNow: number = 0;
  public maxDatesCount: number = 3;
  public durations: SelectOption[] = [];
  public selectedStep = 0;
  public tours: SelectOption[] = [];

  public readonly notesMaxLength: number = SF_CASE_INSTRUCTIONS_MAXLENGTH;

  get isUpdateMode() {
    return this.event.activeTourBooking !== null;
  }

  get requestedObjectId() {
    return this.selectedExhibition ? this.selectedExhibition.id : this.objectId;
  }

  get requestedObjectType() {
    return this.selectFromExhibitions ? EntityTypes.exhibition : this.event.entityTypename || this.event.__typename;
  }

  get exhibitionSelectOptions() {
    if (!this.isUpdateMode) {
      return this.exhibitions;
    }
    return this.exhibitions.filter((exh) => exh.id === this.formData.exhibitionId);
  }

  get selectedExhibition() {
    return this.formData.exhibitionId ? this.exhibitions.find((ex: any) => ex.id === this.formData.exhibitionId) : null;
  }

  get isArtSpaceAsNonPartnerMuseum() {
    return (
      this.event.__typename === EntityTypes.historical_site ||
      (this.object === 'exhibition' &&
        !(this.event.artSpace ? this.event.artSpace.partner_museum : this.event.partner_museum))
    );
  }

  get disabledDates() {
    return this.formData.rawServiceDates.filter((d) => d).map((d) => DateTime.fromISO(d));
  }

  get serviceDates() {
    return this.formData.rawServiceDates
      .map((d) => {
        if (!d) {
          return null;
        }

        const dt = DateTime.fromISO(d);

        if (dt.hour === 0 && dt.minute === 0) {
          return null;
        }

        return dt;
      })
      .filter((d) => d) as (DateTime<true> | DateTime<false>)[];
  }

  get formattedServiceDates() {
    return this.serviceDates.map((dt) => dt.toSQL({ includeOffset: false })) as string[];
  }

  get minServiceDate() {
    return this.serviceDates.length ? DateTime.min(...this.serviceDates) : null;
  }

  get minDate() {
    const minDt = DateTime.now().plus({ days: 1 }).set({ hour: 8, minute: 0, second: 0 });

    if (this.earliestStartDate && this.earliestStartDate > minDt) {
      return this.earliestStartDate.toISO();
    }
    return minDt.toISO();
  }

  get maxDate() {
    const nowDt = DateTime.now();

    if (this.latestEndDate && this.latestEndDate > nowDt) {
      return this.latestEndDate.toISO();
    }
    return nowDt.endOf('year').plus({ years: 5 }).set({ hour: 22, minute: 0, second: 0 }).toISO();
  }

  get earliestStartDate() {
    return this.getBookEntityStartEndDate('earliest_start_date');
  }

  get latestEndDate() {
    return this.getBookEntityStartEndDate('latest_end_date');
  }

  get stepTitle() {
    return this.steps[this.selectedStep].title;
  }

  get isFirstStep() {
    return this.selectedStep === 0;
  }

  get isLastStep() {
    return this.selectedStep + 1 === this.steps.length;
  }

  get currentStep() {
    return this.steps[this.selectedStep];
  }

  get steps() {
    const firstSteps = [];

    if (this.selectFromExhibitions) {
      firstSteps.push({
        type: 'selectFromExhibitions',
        title: this.isUpdateMode ? 'Selected exhibition' : 'Select from exhibitions',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.formData.exhibitionId) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select at least one exhibition.');
        },
      });
    }

    return [
      ...firstSteps,
      {
        type: 'tour',
        title: 'Select tour type',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.formData.tourTypes.length) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select at least one tour.');
        },
      },
      {
        type: 'serviceDates',
        title: 'Select dates & time',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.serviceDates.length) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select at least one date & time.');
        },
      },
      {
        type: 'duration',
        title: 'Select duration',
        validate: () => {
          return new Promise<void>((resolve, reject) => {
            if (this.formData.duration) {
              resolve();
            } else {
              reject();
            }
          });
        },
        onError: () => {
          this.$toast.error('Please select the duration.');
        },
      },
      {
        type: 'guests',
        title: 'Add guests',
      },
    ];
  }

  get showArrangingTourNotice() {
    return this.minServiceDate ? this.bookingDateDaysDiffNow <= 2 : false;
  }

  @Watch('minServiceDate', { immediate: true })
  watchMinServiceDate(newMinServiceDate: DateTime | null) {
    if (newMinServiceDate) {
      this.bookingDateDaysDiffNow = Math.floor(newMinServiceDate.diff(DateTime.now().startOf('day'), 'days').days);
    }
  }

  @Watch('selectFromExhibitions', { immediate: true })
  watchSelectFromExhibitions(newValue: boolean) {
    if (newValue && this.exhibitions.length === 1) {
      this.formData.exhibitionId = this.exhibitions[0].id;
    }
  }

  created() {
    this.fetchBookTourTypes();
    this.fetchBookDurations();

    if (this.event.activeTourBooking) {
      const activeBooking = this.event.activeTourBooking;
      const rawServiceDates = [];

      let idx = 0;
      while (idx < this.maxDatesCount) {
        const dtValue = activeBooking.service_dates[idx] || '';
        rawServiceDates[idx] = dtValue ? DateTime.fromSQL(dtValue).toISO() : dtValue;
        idx++;
      }

      this.formData = {
        id: activeBooking.id,
        adultGuests: activeBooking.guests_adults,
        childrenGuests: activeBooking.guests_children,
        duration: activeBooking.tour_duration?.value || '',
        exhibitionId: activeBooking.bookable_id,
        notes: activeBooking.notes,
        rawServiceDates,
        tourTypes: activeBooking.tour_types,
      };
    }
  }

  getBookEntityStartEndDate(field: 'earliest_start_date' | 'latest_end_date') {
    const dateEntity = this.selectedExhibition ? this.selectedExhibition : this.event;
    let dt = null;

    if (dateEntity[field]) {
      dt = DateTime.fromJSDate(new Date(dateEntity[field]));

      if (!dt.isValid) {
        dt = null;
      }
    }

    return dt;
  }

  async fetchBookTourTypes() {
    const result = await this.$apollo.query({
      query: eventTourTypesQuery,
      fetchPolicy: 'no-cache',
    });
    this.tours = result?.data?.eventTourTypes || [];
  }

  async fetchBookDurations() {
    const result = await this.$apollo.query({
      query: eventTourDurationsQuery,
      fetchPolicy: 'no-cache',
    });
    this.durations = result?.data?.eventTourDurations || [];
  }

  async toNextStep() {
    const validateStep = this.steps[this.selectedStep].validate;

    if (validateStep) {
      try {
        await validateStep();
      } catch {
        const onError = this.steps[this.selectedStep].onError;
        if (onError) {
          onError();
        }
        return;
      }
    }

    if (this.isLastStep) {
      this.submit();
    } else {
      this.selectedStep++;
    }
  }

  toPreviousStep() {
    if (!this.isFirstStep) {
      this.selectedStep--;
    }
  }

  submit() {
    this.$emit('show-loader');

    const inputData: TourBookingRequestInput = {
      duration: this.formData.duration,
      guests_adults: this.formData.adultGuests,
      guests_children: this.formData.childrenGuests,
      notes: this.formData.notes,
      service_dates: this.formattedServiceDates,
      tour_types: this.formData.tourTypes,
    };

    if (this.isUpdateMode) {
      inputData.id = this.formData.id;
    } else {
      inputData.entity_id = this.requestedObjectId;
      inputData.entity_type = this.requestedObjectType;
    }

    this.$apollo
      .mutate({
        mutation: this.isUpdateMode ? updateTourBookingMutation : bookATourMutation,
        variables: {
          input: inputData,
        },
      })
      .then((res: any) => {
        if (res) {
          const message = this.isUpdateMode
            ? 'Thank you! Your booking details have been fully updated.'
            : 'Thank you for your booking. Your Member Liaison is working on confirming a guide for your tour and will contact you shortly to confirm details and associated costs.';

          this.$toast.success(message);
          this.$emit('hide-loader');
          this.$emit('close');

          if (!this.isUpdateMode) {
            this.$emit('requested');
          }
        }
      })
      .catch((err: any) => {
        const error = err?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;

        this.$emit('hide-loader');
        this.$toast.error(error);
      });
  }

  close() {
    this.$emit('close');
  }

  isTourSelected(value: string) {
    return this.formData.tourTypes.includes(value);
  }
}
