

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EntityTypes } from '@/helpers/entityType';

import TicketRequest from '@/components/partials/TicketRequest.vue';
import TourBookingForm from '@/components/partials/TourBookingForm.vue';
import ThePlusCircleIcon from '@/components/icons/ThePlusCircleIcon.vue';
import TheBookTourIcon from '@/components/icons/TheBookTourIcon.vue';
import TheTicketIcon from '@/components/icons/TheTicketIcon.vue';
import TheAwaitingConfirmationIcon from '@/components/icons/TheAwaitingConfirmationIcon.vue';
import TheConfirmedIcon from '@/components/icons/TheConfirmedIcon.vue';
import TheNotConfirmedIcon from '@/components/icons/TheNotConfirmedIcon.vue';

@Component({
  components: {
    TheNotConfirmedIcon,
    TheConfirmedIcon,
    TheAwaitingConfirmationIcon,
    TheTicketIcon,
    TheBookTourIcon,
    ThePlusCircleIcon,
    TicketRequest,
    TourBookingForm,
  },
})
export default class TicketRequestAction extends Vue {
  @Prop() event!: any;
  @Prop() labelClass: string;
  @Prop() object!: String;
  @Prop() objectId!: Number;
  @Prop({ default: false, type: Boolean }) selectFromExhibitions: Boolean;
  @Prop({ default: false, type: Boolean }) show: Boolean;
  @Prop({ default: false, type: Boolean }) tour: Boolean;
  @Prop({ default: 'data-details__action' }) wrapperClass: string;

  isLoader = false;
  showForm = false;
  isRequested = false;

  get accessType() {
    return this.event.accessType || { id: null, name: '' };
  }

  get actionDisabled() {
    return !!this.activeBooking;
  }

  get actionLabel() {
    if (this.activeBooking) {
      return this.activeBooking.status_for_human;
    }

    if (!this.actionDisabled) {
      if (this.tour) {
        return 'Request a Tour';
      }
      if (this.bookAcademySession) {
        return 'Book a Session';
      }
      if (this.accessType.id) {
        return this.accessType.name;
      }
    }

    return '';
  }

  get activeBooking() {
    let activeBooking = null;

    if (this.tour && this.event.activeTourBooking) {
      activeBooking = this.event.activeTourBooking;
    } else if (!this.tour && this.event.activeBooking) {
      activeBooking = this.event.activeBooking;
    }

    if (activeBooking === null && this.isRequested) {
      return {
        id: '',
        is_awaiting_status: true,
        is_confirmed_status: false,
        is_not_confirmed_status: false,
        status_for_human: 'Awaiting confirmation',
      };
    }

    return activeBooking;
  }

  get bookAcademySession() {
    return this.event.__typename === EntityTypes.academy && this.event.based_type !== 'Scheduled';
  }

  get isTourBookingForMuseumExhibition() {
    return (
      this.tour &&
      [EntityTypes.art_space, EntityTypes.exhibition, EntityTypes.historical_site].includes(this.event.__typename)
    );
  }

  get exhibitions() {
    return this.selectFromExhibitions ? this.event.exhibitions || [] : [];
  }

  get isCreateMode() {
    return !this.activeBooking?.id;
  }

  get showAction() {
    return this.actionDisabled || (!this.event.is_expired && !this.event.fully_booked);
  }

  @Watch('show', { immediate: true })
  watchShow(newValue: boolean) {
    if (newValue) {
      this.open(true);
    }
  }

  handleRequested() {
    this.isRequested = true;
    this.$emit('requested');
  }

  handleOpen() {
    if (!this.actionDisabled) {
      this.open();
    }
  }

  open(ignoreDisabled?: boolean) {
    if (!ignoreDisabled && this.actionDisabled) {
      return;
    }
    this.showForm = true;
    this.$store.dispatch('disableScroll');
  }

  close() {
    this.showForm = false;
    this.$emit('close');
    this.$store.dispatch('enableScroll');
  }

  closeByOverlay() {
    if (this.isCreateMode) {
      this.close();
    }
  }
}
