<template>
  <label>
    <span v-show="label" class="label-text">{{ label }}</span>
    <textarea v-model.trim="vModel" :maxlength="maxlength" :placeholder="placeholder" rows="2" type="text" />
  </label>
</template>

<script>
export default {
  name: 'BookingFormTextarea',
  props: {
    label: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
label,
textarea {
  width: 100%;
}

label,
.label-text {
  display: block;
}

label {
  margin-bottom: 28px;
}

.label-text {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

textarea {
  resize: vertical;
  min-height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  color: #363636;
  border: 1px solid #acacac;

  &::placeholder {
    color: rgba(#363636, 0.7);
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
}
</style>
