<template>
  <div class="datetime-widget">
    <div class="date-input" @click="open">
      <span class="date-input__label">{{ label }}</span>

      <div class="date-input__picker">
        <input
          v-bind="$attrs"
          v-on="$listeners"
          class="vdatetime-input"
          :class="inputClass"
          :placeholder="`Select ${type}`"
          type="text"
          :value="inputValue"
          @focus="open"
        />
      </div>

      <span class="date-input__arrow" :class="{ 'date-input__arrow--opened': isOpen }">
        <TheArrowDownIcon />
      </span>
    </div>

    <TransitionGroup name="vdatetime-fade" tag="div">
      <div v-if="isOpen" class="vdatetime-overlay" key="overlay" @click.self="close" />
      <DatetimePopup
        v-if="isOpen"
        :auto="auto"
        :datetime="popupDate"
        :disabled-dates="disabledDates"
        :hour-step="hourStep"
        key="popup"
        :max-datetime="popupMaxDatetime"
        :min-datetime="popupMinDatetime"
        :minute-step="minuteStep"
        :phrases="phrases"
        :type="type"
        :use12-hour="use12Hour"
        :week-start="weekStart"
        @cancel="close"
        @confirm="confirm"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import { DateTime, Settings as LuxonSettings } from 'luxon';
import { datetimeFromISO, isToday, weekStart } from './util';

import DatetimePopup from './DatetimePopup';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';

export default {
  name: 'DatetimeWidget',
  components: {
    TheArrowDownIcon,
    DatetimePopup,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    valueZone: {
      type: String,
      default: 'UTC',
    },
    inputClass: {
      type: String,
      default: '',
    },
    hiddenName: {
      type: String,
    },
    zone: {
      type: String,
      default: 'local',
    },
    format: {
      type: [Object, String],
      default: null,
    },
    type: {
      type: String,
      default: 'date',
    },
    phrases: {
      type: Object,
      default: () => ({
        cancel: 'Cancel',
        ok: 'Ok',
      }),
    },
    use12Hour: {
      type: Boolean,
      default: false,
    },
    hourStep: {
      type: Number,
      default: 1,
    },
    minuteStep: {
      type: Number,
      default: 1,
    },
    minDatetime: {
      type: String,
      default: null,
    },
    maxDatetime: {
      type: String,
      default: null,
    },
    auto: {
      type: Boolean,
      default: false,
    },
    weekStart: {
      type: Number,
      default() {
        return weekStart();
      },
    },
  },
  data() {
    return {
      isOpen: false,
      datetime: datetimeFromISO(this.value),
    };
  },

  computed: {
    inputValue() {
      let format = this.format;

      if (this.type === 'date' && isToday(new Date(this.value))) {
        return 'Today';
      }

      if (!this.datetime) {
        return '';
      }

      const dt = DateTime.fromISO(this.datetime).setZone(this.zone);

      if (this.type === 'time' && dt.hour === 0 && dt.minute === 0) {
        return '';
      }

      if (!format) {
        switch (this.type) {
          case 'date':
            format = DateTime.parseFormatForOpts(DateTime.DATE_MED);
            break;
          case 'time':
            format = 'HH:mm';
            break;
          default:
            format = DateTime.parseFormatForOpts(DateTime.DATETIME_MED);
        }
      }

      return dt.toFormat(format);
    },
    popupDate() {
      return this.datetime ? this.datetime.setZone(this.zone) : null;
    },
    popupMinDatetime() {
      return this.minDatetime ? DateTime.fromISO(this.minDatetime).setZone(this.zone) : null;
    },
    popupMaxDatetime() {
      return this.maxDatetime ? DateTime.fromISO(this.maxDatetime).setZone(this.zone) : null;
    },
  },
  watch: {
    value(newValue) {
      this.datetime = datetimeFromISO(newValue);
    },
  },

  created() {
    LuxonSettings.defaultLocale = 'EN-en';
    this.emitInput();
  },

  methods: {
    confirm(datetime) {
      let newDt = DateTime.fromISO(datetime.toISO());
      const oldDt = DateTime.fromISO(this.value);

      if (!this.value && this.type === 'date') {
        newDt = newDt.set({ hour: 0, minute: 0, seconds: 0 });
      } else if (oldDt && this.type === 'date') {
        newDt = newDt.set({ hour: oldDt.hour, minute: oldDt.minute, seconds: oldDt.second });
      }

      this.datetime = newDt;
      this.emitInput();
      this.close();
    },
    emitInput() {
      const datetime = this.datetime ? this.datetime : null;
      this.$emit('input', datetime ? datetime.toISO() : '');
    },
    open(event) {
      event.target.blur();

      if (!this.disabled) {
        this.isOpen = true;
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
  },
};
</script>
